/* eslint-disable valid-jsdoc */
/* istanbul ignore file */
import { ajaxCall } from '../../FunctionModules/AjaxHelper/ajax-helper';
import { AjaxServerError, CapturePaymentTimeoutError, JSONError } from '../../FunctionModules/ErrorHandling/error-types';
import { logError } from '../../FunctionModules/Logger/log';
import { logInfo } from '../../FunctionModules/Logger/log-uploader';

export async function createPayment(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/payment/create`,
    method: 'POST',
    data: requestBody,
  });
}

/**
 * @typedef BlueSnapPaymentMetaData
 * @type {object}
 * @property {string} CauseId - Provided
 * @property {string} FirstName
 * @property {string} LastName
 * @property {string} EmailAddress
 * @property {string} CountryCode
 * @property {string} Address
 * @property {string} ProvinceState
 * @property {string} PostalZip
 * @property {string} City
 */

/**
 * @typedef UpdatePaymentRequest
 * @type {object}
 * @property {string} PaymentId
 * @property {BlueSnapPaymentMetaData} MetaData
 * @property {number} GrossAmount - Amount can be updated in update payment API.
 * @property {string} CurrencyCode
 */

/**
 * @typedef UpdatePaymentResponse
 * @type {object}
 * @property {string} PaymentId
 */

/**
 * API to add more information to a payment.
 * @param {UpdatePaymentRequest} requestBody - TODO
 * @returns {Promise|Error} - No meaningful fields in response for now. Only need to verify that this request succeed.
 */
export async function updatePayment(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/payment/update`,
    method: 'POST',
    data: requestBody,
  });
}

/**
 * @typedef UpdatePaymentInstrumentRequest
 * @type {object}
 * @property {string} PaymentInstrumentId
 * @property {string} PaymentInstrumentNumber
 */

/**
 * API to add more information to a payment.
 * @param {UpdatePaymentInstrumentRequest} requestBody - TODO
 * @returns {Promise|Error} - No meaningful fields in response for now. Only need to verify that this request succeed.
 */
export async function updatePaymentInstrument(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/paymentinstr/update`,
    method: 'POST',
    data: requestBody,
  });
}

/**
 * @typedef CreatePaymentInstrumentTokenRequest
 * @type {object}
 * @property {string} PaymentInstrumentId
 * @property {number} PaymentInstrumentTokenProvider
 * @property {string} Token
 */

/**
 *
 * @param {CreatePaymentInstrumentTokenRequest} requestBody - Request body
 * @returns {Promise | Error} - Returns
 */

export async function createPaymentInstrumentToken(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/paymentinstr/createPIToken`,
    method: 'POST',
    data: requestBody,
  });
}

export async function createPaymentInstrumentWithTokenMetadata(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/paymentinstr/createPIWithTokenMetadata`,
    method: 'POST',
    data: requestBody,
  });
}

/**
 * @typedef CreatePaymentInstrumentRequest
 * @type {object}
 * @property {string} ownerId
 * @property {string} PaymentInstrumentDirection
 * @property {number} PaymentInstrumentTokenProvider
 * @property {string} PaymentInstrumentNumber
 */

/**
 * @typedef CreatePaymentInstrumentResponse
 * @type {object}
 * @property {string} PaymentInstrumentId
 */

/**
 *
 * @param {CreatePaymentInstrumentRequest} requestBody - Request body
 * @returns {Promise<CreatePaymentInstrumentResponse> | Error} - Returns
 */

export async function createPaymentInstrument(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/paymentinstr/createPI`,
    method: 'POST',
    data: requestBody,
  });
}

export async function getPaymentDetail(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/payment/detail`,
    method: 'POST',
    data: requestBody,
  });
}

export async function createRewardUser(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/donation/joinRewards`,
    method: 'POST',
    data: requestBody,
  });
}

export async function getRewardsBalance(origin = '') {
  return ajaxCall({
    url: `${origin}/api/donation/getRewardsBalance`,
    method: 'GET',
  });
}

export async function donatePoints(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/donation/donatePointsWithSyncFlow`,
    method: 'POST',
    data: requestBody,
  });
}

export function capturePayment(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/payment/capture`,
    method: 'POST',
    data: requestBody,
  }).catch((err) => {
    if (err.constructor === AjaxServerError && err.message === 'TIMEOUT') {
      logError('capturePayment timeout by AjaxServerError');
      throw new CapturePaymentTimeoutError('Status Code: 500, Text: TIMEOUT', requestBody.PaymentId);
    } else if (err.constructor === JSONError && err.data && err.data.message === 'TIMEOUT') {
      logError(`capturePayment timeout by JSONError. ErrorCodes=${err.data.errorCodes}`);
      throw new CapturePaymentTimeoutError('Status Code: 500, Text: TIMEOUT', requestBody.PaymentId);
    }
    throw err;
  });
}

export const validateAuthToken = async (origin = '') => ajaxCall({
  url: `${origin}/login/validateAuthToken`,
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
}).then(({ result }) => {
  logInfo(`Auth Validation Result: ${result}`);
}).catch((err) => {
  logError('xPay Authentication failed');
  throw err;
});

export const correlateSession = async (origin = '') => ajaxCall({
  url: `${origin}/login/correlateSession`,
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * @typedef CheckPaymentStatusRequest
 * @type {object}
 * @property {string} PaymentId
 */

/**
 *
 * @param {CheckPaymentStatusRequest} requestBody - Request body
 * @returns { Promise | Error }
 */

export async function confirmPaymentStatus(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/payment/confirmStatus`,
    method: 'POST',
    data: requestBody,
    headers: {
      'content-type': 'application/json',
    },
  });
}

/**
 * @typedef GetPaymentHistoryRequest
 * @type {object}
 * @property {number} OrderType - the category of history to fetch
 * @property {string} [After] - Which items to fetch
 */

/**
 * @param {GetPaymentHistoryRequest} requestBody - Request body
 * @returns { Promise | Error }
 */
export async function getPaymentHistory(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/getHistoryData`,
    data: requestBody,
  });
}

export async function createGuestAuth(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/donation/authGuest`,
    data: requestBody,
  });
}

export async function getLatestDonationEmail(origin = '') {
  return ajaxCall({
    url: `${origin}/api/donation/GetLatestDonationEmailAddress`,
    method: 'GET',
  });
}

export async function getRecurringDonationPlans(requestBody, origin = '') {
  return ajaxCall({
    url: `${origin}/api/donation/getRecurringDonationPlans`,
    data: requestBody,
  });
}
